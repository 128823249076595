<template>
  <deposit-report></deposit-report>
</template>

<script>
import DepositReport from '@/components/Reports/Accounting/DepositReport.vue'

export default {
  components: { DepositReport },
}
</script>
