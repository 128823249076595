<template>
  <div class="items-report">
    <v-card class="mb-2">
      <v-card-title>Laporan Pemasukan</v-card-title>

      <v-divider></v-divider>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>Filter</v-expansion-panel-header>

          <v-expansion-panel-content>
            <vc-date-picker
              v-model="dateRange"
              :first-day-of-week="2"
              :masks="{ input: 'DD MMMM YYYY' }"
              is-range
            >
              <template v-slot="{ inputValue, inputEvents }">
                <v-text-field
                  v-on="inputEvents.start"
                  :value="inputValue.start"
                  class="mb-2"
                  label="Mulai"
                  hide-details
                  outlined
                  readonly
                ></v-text-field>

                <v-text-field
                  v-on="inputEvents.end"
                  :value="inputValue.end"
                  label="Sampai"
                  hide-details
                  outlined
                  readonly
                ></v-text-field>
              </template>
            </vc-date-picker>

            <div class="d-flex mt-2">
              <v-btn class="ml-auto" color="light-blue" @click="fetchData">
                Cari
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-card class="mb-2" color="#15263b">
      <v-card-title class="pb-1">Total Pemasukan</v-card-title>

      <v-card-text>
        <h2>{{ total != 0 ? currencyFormat(total) : 0 }}</h2>
      </v-card-text>
    </v-card>

    <v-btn
      class="mb-2"
      color="primary"
      to="/accounting/deposits/create"
      x-large
      block
    >
      Buat Pemasukan
    </v-btn>

    <v-card class="mb-2">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="fetchData"
      ></v-text-field>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count"
        class="elevation-1"
      >
        <template #[`item.destination`]="{ item }">
          <span v-if="item.person_type_id == 0">Lain - lain</span>

          <span v-if="item.person_type_id == 2">
            Customer - {{ item.person_name }}
          </span>

          <span v-if="item.person_type_id == 3">
            Supplier - {{ item.person_name }}
          </span>
        </template>

        <template #[`item.amount`]="{ item }">
          {{ currencyFormat(item.amount) }}
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn color="light-blue" @click="openDetails(item.trans_no)">
            Details
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateRange: this.getMonthDateRange(),
      search: '',
      options: {},
      headers: [
        { text: 'Referensi', value: 'ref' },
        { text: 'Tanggal', value: 'trans_date' },
        { text: 'Untuk', value: 'destination' },
        { text: 'Nilai', value: 'amount' },
        { text: '', sortable: false, value: 'actions' },
      ],
      items: [],
      count: 0,
      total: 0,
      loading: false,
    }
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.fetchData()
      },
    },
  },

  methods: {
    currencyFormat(value) {
      return Intl.NumberFormat('id-ID', {
        currency: 'IDR',
      }).format(value)
    },

    fetchData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      this.items = []
      this.loading = true
      this.$http
        .get('/accounting/deposits', {
          params: {
            search: this.search,
            orderBy: sortBy[0] ? sortBy[0] : '',
            orderDesc: sortDesc[0] ? 1 : 0,
            offset: (page - 1) * itemsPerPage,
            limit: itemsPerPage > 0 ? itemsPerPage : '',
            startDate: this.date2sql(this.dateRange.start),
            endDate: this.date2sql(this.dateRange.end),
          },
        })
        .then((result) => {
          this.items = result.data.result
          this.count = result.data.count
          this.total = result.data.total
          this.loading = false
        })
    },

    openDetails(transNo) {
      this.$router.push('/accounting/deposits/' + transNo)
    },
  },
}
</script>
